import React, { useRef } from 'react'
import useIntersectionObserver from '@react-hook/intersection-observer'
import { vidWrap, vid } from './style.module.scss'

const Oembed = ({ url, title }) => {
    const containerRef = useRef()
    const lockRef = useRef(false)
    const { isIntersecting } = useIntersectionObserver(containerRef)
    if (isIntersecting) {
        lockRef.current = true
    }
    return (
        <div className={vidWrap} ref={containerRef}>
            {lockRef.current && (
                <iframe
                    loading="lazy"
                    title={title}
                    className={vid}
                    src={url}
                    frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="allowfullscreen"
                ></iframe>
            )}
        </div>
    )
}

export default Oembed
