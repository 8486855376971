import React from 'react'

import { container } from './style.module.scss'

const Container = ({ children, ...rest }) => {
  return (
    <div className={container} {...rest}>
      {children}
    </div>
  )
}

export default Container
