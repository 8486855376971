import Container from "../../../../src/containers/Container";
import FaqWrapper from "../../../../src/containers/FaqWrapper";
import FaqAnswer from "../../../../src/containers/FaqAnswer";
import AdBlock from "../../../../src/components/AdBlock";
import Carousel from "../../../../src/components/Carousel";
import Oembed from "../../../../src/components/Oembed";
import * as React from 'react';
export default {
  Container,
  FaqWrapper,
  FaqAnswer,
  AdBlock,
  Carousel,
  Oembed,
  React
};