import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Glide from '@glidejs/glide'
import { getImage, GatsbyImage, withArtDirection } from 'gatsby-plugin-image'
import {
  accessible,
  glideBullets,
  glideSlides,
  glideArrows,
  glideArrow,
  glideArrowInner,
  glideArrowRight,
} from './style.module.scss'

const imageData = require(`../../content/${process.env.GATSBY_SITEVERSION}/galleryData.json`)

const Carousel = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        humanBuildTime: buildTime(formatString: "Do MMMM YYYY")
        copywriteYear: buildTime(formatString: "YYYY")
        buildTime
      }
      allFile(
        filter: { sourceInstanceName: { eq: "galleryImages" } }
        sort: { order: ASC, fields: relativePath }
      ) {
        nodes {
          name
          small: childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 700
            )
          }
          large: childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 1080
            )
          }
        }
      }
    }
  `)

  const slides = data.allFile.nodes

  const glideRef = useRef(null)

  // Create slide array
  let completeSlides = []
  if (slides) {
    completeSlides = slides.map(({ large, small, name }) => {
      const details = imageData.find(({ file }) => file === name)

      const artDirectedImage = withArtDirection(getImage(large), [
        {
          media: '(max-width: 700px)',
          image: getImage(small),
        },
      ])
      return {
        alt: details.alt,
        image: artDirectedImage,
      }
    })
  }

  useEffect(() => {
    const glide = new Glide(glideRef.current, {
      breakpoints: {
        767: {
          gap: 5,
          peek: 20,
          perView: 1,
        },
        800: {
          gap: 10,
          peek: 80,
          perView: 1,
        },
        824: {
          gap: 10,
          peek: 10,
          perView: 2,
        },
        992: {
          gap: 10,
          peek: 80,
          perView: 1,
        },
        1600: {
          gap: 10,
          peek: 20,
          perView: 2,
        },
        2400: {
          gap: 10,
          peek: 160,
          perView: 2,
        },
      },
      focusAt: 'center',
      gap: 10,
      peek: 0,
      perView: 4,
      startAt: 1,
      swipeThreshold: 30,
    })

    glide.mount()
  }, [glideRef])

  if (!completeSlides || completeSlides.length < 1) {
    return null
  }

  return (
    <div ref={glideRef} className="glide">
      <div data-glide-el="track">
        <ul className={`${glideSlides} glide__slides`}>
          {completeSlides.map((slide) => (
            <li className="glide__slide" key={slide.alt}>
              <GatsbyImage alt={slide.alt} image={slide.image} />
            </li>
          ))}
        </ul>
      </div>

      <div className={glideArrows} data-glide-el="controls">
        <button className={glideArrow} data-glide-dir="<" type="button">
          <div className={glideArrowInner} tabIndex="-1">
            <span className={accessible}>Previous Slide</span>
          </div>
        </button>
        <button className={glideArrow} data-glide-dir=">" type="button">
          <div
            className={`${glideArrowInner} ${glideArrowRight}`}
            tabIndex="-1"
          >
            <span className={accessible}>Next Slide</span>
          </div>
        </button>
      </div>

      <div className={glideBullets} data-glide-el="controls[nav]">
        {completeSlides.map((slide, index) => (
          <div className="glide__bullet" key={`slide-${index + 1}`}>
            <div className="bullet_inner" tabIndex="-1" />
            <span className={accessible}>Slide {index + 1}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Carousel
