import React from 'react'

const FaqWrapper = ({ children }) => {
  return (
    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      {children}
    </div>
  )
}

export default FaqWrapper
