import React from 'react'

const FaqAnswer = ({ children }) => {
  return (
    <div
      itemScope
      itemProp="acceptedAnswer"
      itemType="https://schema.org/Answer"
    >
      <div itemProp="text">{children}</div>
    </div>
  )
}

export default FaqAnswer
