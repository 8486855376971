import React from 'react'
import AdSense from 'react-adsense'
import { adBlock, title, theAd } from './style.module.scss'

const AdBlock = ({ count }) => (
  <div className={adBlock}>
    <p className={title}>A loverly little ad break! Continue reading below.</p>
    <div className={theAd}>
      <AdSense.Google
        client="ca-pub-9412125337838543"
        slot="1111528544"
        style={{ display: 'block' }}
        layout="in-article"
        format="fluid"
      />
    </div>
  </div>
)

export default AdBlock
